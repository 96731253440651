import { useLocalStorage } from 'usehooks-ts';

import { useDailySeed } from './useDailySeed';
import { ChoiceStatus } from './useRoundState';

export const EMOJI_LIST: { round: string; emoji: string | string[] }[] = [
  { round: 'shape', emoji: '🗺️' },
  { round: 'emblem', emoji: '🛡️' },
  { round: 'capital-flags', emoji: '⛳️' },
  { round: 'border-flag', emoji: '🧭' },
  { round: 'quiz', emoji: ['👫', '🪙'] },
];

export const useRoundEmojis = () => {
  const dailySeed = useDailySeed();

  const seeds = ['shape', 'emblem', 'capital-flags', 'border-flag', 'quiz'];
  const bonusRoundsSeeds = seeds.map(
    (round) => `${dailySeed}-${round}-bonus-round`,
  );

  const [dailyChoicesShape] = useLocalStorage(bonusRoundsSeeds[0], {});
  const [dailyChoicesEmblem] = useLocalStorage(bonusRoundsSeeds[1], {});
  const [dailyChoicesCapitalFlag] = useLocalStorage(bonusRoundsSeeds[2], {});
  const [dailyChoicesBorderFlag] = useLocalStorage(bonusRoundsSeeds[3], {});
  const [dailyChoicesQuiz] = useLocalStorage(bonusRoundsSeeds[4], {});

  const combinedRoundsChoices = [
    { round: 'shape', values: dailyChoicesShape },
    { round: 'emblem', values: dailyChoicesEmblem },
    { round: 'capital-flags', values: dailyChoicesCapitalFlag },
    { round: 'border-flag', values: dailyChoicesBorderFlag },
    { round: 'quiz', values: dailyChoicesQuiz },
  ];

  const emojis = combinedRoundsChoices.reduce<string[]>(
    (emojiList, { round, values }) => {
      if (!values || Object.keys(values).length === 0) {
        return emojiList;
      }

      if (round === 'quiz') {
        const quizValues = Object.values(values) as {
          answer: ChoiceStatus;
        }[];

        quizValues.forEach((quiz, index) => {
          if (quiz?.answer === ChoiceStatus.CORRECT) {
            const emojiEntry = EMOJI_LIST.find(
              (entry) => entry.round === round,
            );
            if (emojiEntry && Array.isArray(emojiEntry.emoji)) {
              emojiList.push(emojiEntry.emoji[index]);
            }
          }
        });
      } else {
        const isCorrect = Object.values(values).some(
          (status) => status === ChoiceStatus.CORRECT,
        );

        if (isCorrect) {
          const emojiEntry = EMOJI_LIST.find((entry) => entry.round === round);
          if (emojiEntry) {
            if (Array.isArray(emojiEntry.emoji)) {
              emojiList.push(...emojiEntry.emoji);
            } else {
              emojiList.push(emojiEntry.emoji);
            }
          }
        }
      }

      return emojiList;
    },
    [],
  );

  return emojis.join('');
};

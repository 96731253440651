import { styled } from 'styled-components';

import { BackButton } from '../components/BackButton';
import EmojiRender from '../components/EmojiRender';
import { GuessList } from '../components/GuessList';
import { useDistanceInUserUnit } from '../hooks/useDistanceInUserUnit';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 12px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 20px;
`;

export const RulesRoute = () => {
  const { formatDistance } = useDistanceInUserUnit();

  return (
    <Container>
      <BackButton />

      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <h3 className="text-xl font-bold mb-3">How to play</h3>
        <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
          <div>
            Guess the Flag<span style={{ color: '#1a76d2' }}>le</span> in 6
            guesses.
          </div>
          <div>Each guess must be a valid country or territory.</div>
          <div>
            After each guess, you will have the distance, the direction and the
            proximity from your guess to the target location.
          </div>
        </div>
        <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
          <div className="font-bold">Examples</div>
          <div>
            <GuessList
              guesses={[
                {
                  name: 'Chile',
                  distance: 13_557_000,
                  direction: 'NE',
                  tile: 1,
                },
              ]}
            />
            <div className="my-2">
              Your guess <span className="uppercase font-bold">Chile</span> is{' '}
              {formatDistance(13557000)} away from the target location, the
              target location is in the North-East direction and you have a only
              32% of proximity because it&apos;s quite far away!
            </div>
          </div>
          <div>
            <GuessList
              guesses={[
                {
                  name: 'Finland',
                  direction: 'SE',
                  distance: 3_206_000,
                  tile: 4,
                },
              ]}
            />
            <div className="my-2">
              Your second guess{' '}
              <span className="uppercase font-bold">Finland</span> is getting
              closer! {formatDistance(3206000)} away, South-East direction and
              83%!
            </div>
          </div>
          <div>
            <GuessList
              guesses={[
                {
                  name: 'Lebanon',
                  direction: 'N',
                  distance: 0,
                  tile: 6,
                },
              ]}
            />
            <div className="my-2">
              Next guess, <span className="uppercase font-bold">Lebanon</span>,
              it&apos;s the location to guess! Congrats!{' '}
              <EmojiRender text="🎉" options={{ className: 'inline-block' }} />
            </div>
          </div>
        </div>
        <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3 font-bold">
          A new Flag<span style={{ color: '#1a76d2' }}>le</span> will be
          available every day!
        </div>
        <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
          <div className="font-bold">Any question or suggestion?</div>
          <div>
            Check the{' '}
            <a
              className="underline"
              href="https://www.flagle.io/about"
              target="_blank"
              rel="noopener noreferrer"
            >
              Flagle FAQ
            </a>
          </div>
        </div>
        <div className="space-y-3 text-justify pb-2">
          <div>
            Made by{' '}
            <a
              className="underline"
              href="https://twitter.com/teuteuf"
              target="_blank"
              rel="noopener noreferrer"
            >
              @teuteuf
            </a>{' '}
          </div>
        </div>
        <div className="space-y-3 text-justify pb-3 border-b-2 border-gray-200 mb-3">
          <div>
            &nbsp;Ads by
            <a
              className="underline pl-1"
              href={'https://Snigel.com'}
              target="_blank"
              rel="noreferrer"
            >
              Snigel
            </a>
          </div>
        </div>
        <div className="copy flex justify-center items-center">
          <a
            href="https://teuteuf.fr/privacy.html"
            className="underline pl-1"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <a
            className="underline"
            href="https://teuteuf.notion.site/17eeff4e9f3880e2a6aeef075e74939b?pvs=105"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feedback
          </a>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          <a
            className="underline font-bold"
            href="https://teuteuf.fr/jobs.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            We{"'"}re Hiring!
          </a>
        </div>
      </div>
    </Container>
  );
};

export default RulesRoute;

import { memo, ReactNode, useEffect, useState } from 'react';
import Faq from 'react-faq-component';
import { JsonLd } from 'react-schemaorg';
import { FAQPage } from 'schema-dts';
import Showdown from 'showdown';

const styles = {
  bgColor: 'transparent',
  titleTextColor: 'currentColor',
  rowTitleColor: 'currentColor',
  rowContentColor: 'currentColor',
  arrowColor: 'currentColor',
  titleTextSize: '24px',
  rowContentTextSize: '16px',
  rowContentPaddingTop: '10px',
  rowContentPaddingBottom: '10px',
};

export interface FaqItemProp {
  content: string;
  title: string;
  collapsed?: boolean;
  text?: string;
}

export interface FaqProp {
  title: string;
  rows: FaqItemProp[];
}

export const FaqSection = () => {
  const [schema, setSchema] = useState<ReactNode>();
  const [faq, setFaq] = useState<FaqProp | undefined>(undefined);

  useEffect(() => {
    if (faq === undefined) {
      fetch('/flagle-faq.md')
        .then((res) => res.text())
        .then(
          (result) => {
            const questions = result.trim().split('##');

            const fq: FaqProp = {
              title: 'F.A.Q.',
              rows: [],
            };

            const faqSchema = (fq: FaqItemProp[]) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const faqItems = new Array<any>();

              for (const fqq of fq) {
                faqItems.push({
                  '@type': 'Question',
                  name: fqq.title,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: fqq.text,
                  },
                });
              }

              return (
                <>
                  <JsonLd<FAQPage>
                    item={{
                      '@context': 'https://schema.org',
                      '@type': 'FAQPage',
                      mainEntity: faqItems,
                    }}
                  />
                </>
              );
            };

            for (const question of questions) {
              if (question.trim() !== '') {
                const qlines = question.trim().split('\n');
                const qdata = qlines.slice(1);
                const fquestion: FaqItemProp = {
                  title: qlines[0],
                  content: qdata.join('\n'),
                };
                const converter = new Showdown.Converter();
                const text = fquestion.content;
                fquestion.text = text;
                fquestion.content = converter.makeHtml(text);
                fq.rows.push(fquestion);
              }
            }

            setFaq(fq);
            setSchema(faqSchema(fq.rows));
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }, [faq]);

  return (
    <>
      <div className="p-2 pt-8 w-full flex faq-content">
        {schema}
        {faq && <Faq data={faq} styles={styles} />}
      </div>
    </>
  );
};

export default memo(FaqSection);

const roundsCompletedStorageKey = 'flagle-rounds-completed';

export function loadCompleted(): Record<string, string[]> {
  const storedCompleted = localStorage.getItem(roundsCompletedStorageKey);
  return storedCompleted != null ? JSON.parse(storedCompleted) : {};
}

export function saveCompleted(dayString: string, completedRound: string): void {
  const allCompleted = loadCompleted();
  const roundsCompleted = loadCompleted()[dayString] ?? [];
  if (roundsCompleted.includes(completedRound)) return;
  roundsCompleted.push(completedRound);

  localStorage.setItem(
    roundsCompletedStorageKey,
    JSON.stringify({
      ...allCompleted,
      [dayString]: roundsCompleted,
    }),
  );
}

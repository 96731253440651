// import Helmet from 'react-helmet';
import { List, ListItem } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from 'styled-components';

import { BackButton } from '../components/BackButton';
import { useStats } from '../hooks/useStats';

interface StatsTileProps {
  value: number;
  name: string;
}

const Type = styled(Typography)`
  margin-top: 5px !important;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 12px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 20px;
`;

function StatsTile({ value, name }: StatsTileProps) {
  return (
    <div className="flex flex-col m-2 max-w-min">
      <p className="text-3xl font-bold text-center">{value}</p>
      <p className="text-center">{name}</p>
    </div>
  );
}

export const StatsRoute = () => {
  const { played, winRatio, currentStreak, maxStreak, guessDistribution } =
    useStats();

  const maxDistribution = Math.max(...Object.values(guessDistribution));

  return (
    <>
      {/* <Helmet>
        <title>Statistics | Flagle</title>
        <meta
          name="description"
          content="Keep track of your daily Worldle stats and streaks. Wordle Game in the world."
        />
      </Helmet> */}

      <Container>
        <BackButton />

        <div className="mt-4 w-full">
          <h3 className="text-xl font-bold text-center">Statistics</h3>
          <div className="flex justify-center">
            <StatsTile value={played} name="Played" />
            <StatsTile value={Math.round(winRatio * 100)} name="Win %" />
            <StatsTile value={currentStreak} name="Current Streak" />
            <StatsTile value={maxStreak} name="Max Streak" />
          </div>
          <div className="my-6">
            <h3 className="text-xl font-bold text-center">
              Guess distribution
            </h3>

            <List>
              {guessDistribution.map((count, index) => (
                <ListItem sx={{ paddingBottom: 0 }} key={index}>
                  <div>{index + 1}</div>
                  <div
                    className="flex-shrink-0 flex-grow bg-gray-300 p-0.5 px-1.5 rounded-sm ml-2 dark:text-black"
                    style={{
                      flex: `0 1 ${Math.round((count / maxDistribution) * 100)}%`,
                    }}
                  >
                    {count}
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </Container>

      <Type id="modal-modal-description" sx={{ m: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            window.open('https://crisisrelief.un.org/t/ukraine');
          }}
        >
          🇺🇦 Donate to Ukraine ❤️
        </Button>
      </Type>
    </>
  );
};

export default StatsRoute;

import { useTodaysCountry } from '../providers/TodaysCountryProvider';
import EmojiRender from './EmojiRender';

export const NextBonusRoundButton = ({
  nextBonusRoundIndex,
  width,
}: {
  nextBonusRoundIndex: number;
  width?: string;
}) => {
  const { rounds, navToRound } = useTodaysCountry();

  return (
    <button
      onClick={() => {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }, 250);
        navToRound(rounds[nextBonusRoundIndex]);
      }}
      className={`h-10 rounded-lg bg-green-600 hover:bg-green-700 active:bg-green-700 text-white font-semibold px-2 py-1 text-md flex items-center justify-center ${width ?? 'w-full'}`}
    >
      <EmojiRender text="🎁" className="inline-block mr-2" />
      {nextBonusRoundIndex === 1 ? 'Play bonus round' : 'Next bonus round'}
    </button>
  );
};

import { useMemo } from 'react';

import { useTodaysCountry } from '../providers/TodaysCountryProvider';
import EmojiRender from './EmojiRender';

export const WikipediaAndMapsLinks = () => {
  const { todaysCountry } = useTodaysCountry();

  const googleMapsUrl = useMemo(() => {
    return todaysCountry.links
      .find((link) => link.type === 'GoogleMaps')
      ?.url.replace('${cc}', 'en');
  }, [todaysCountry.links]);

  const wikipediaUrl = useMemo(() => {
    return todaysCountry.links
      .find((link) => link.type === 'Wikipedia')
      ?.url.replace('${cc}', 'en');
  }, [todaysCountry.links]);

  return (
    <div className="flex w-full flex-row gap-4">
      <a
        className="flex w-full h-10 border rounded-lg px-2 py-1 text-center items-center justify-center font-semibold whitespace-nowrap hover:bg-gray-200 hover:dark:bg-gray-500"
        href={wikipediaUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <EmojiRender text={'📚 Wikipedia'} options={{ className: 'pr-0.5' }} />
      </a>
      <a
        className="flex w-full h-10 border rounded-lg px-2 py-1 text-center items-center justify-center font-semibold whitespace-nowrap hover:bg-gray-200 hover:dark:bg-gray-500"
        href={googleMapsUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <EmojiRender
          text={'🗺️ Google Maps'}
          options={{ className: 'pr-0.5' }}
        />
      </a>
    </div>
  );
};

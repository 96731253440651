import { TeuteufGames } from '@pla324/teuteuf-teuteuf-games';

import { BackButton } from '../components/BackButton';

export function TeuteufGamesRoute() {
  return (
    <div className="flex flex-col w-full items-start px-3 text-start mt-5">
      <BackButton />
      <TeuteufGames utmSource="flagle" />
    </div>
  );
}

import { AdnginEndMobile0 } from './AdnginEndMobile0';
import { NextBonusRoundButton } from './NextBonusRoundButton';
import { ShareButton } from './ShareButton';
import { WikipediaAndMapsLinks } from './WikipediaAndGmapsLinks';

export const RoundEndedButtons = ({
  nextBonusRoundIndex,
}: {
  nextBonusRoundIndex?: number;
}) => {
  return (
    <div className="flex flex-col w-full px-2 gap-4 justify-center items-center">
      <div className="flex gap-4 w-full justify-center items-center">
        <WikipediaAndMapsLinks />
        <ShareButton />
      </div>
      {nextBonusRoundIndex !== undefined && (
        <div className="flex flex-col md:flex-row gap-4 w-full justify-center items-center">
          <NextBonusRoundButton nextBonusRoundIndex={nextBonusRoundIndex} />
        </div>
      )}
      <AdnginEndMobile0 />
    </div>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

import EmojiRender from './EmojiRender';

export const HowToLinkIcon: React.FC = () => {
  return (
    <Link to="/rules">
      <EmojiRender text="❓" className="mr-3 ml-3 text-xl" />
    </Link>
  );
};

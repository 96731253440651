import { FC, SelectHTMLAttributes } from 'react';
import styled from 'styled-components';

import { BackButton } from '../components/BackButton';
import { useSettings } from '../hooks/useSettings';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 12px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 20px;
`;

const Title = styled.h1`
  font-size: 1.3rem;
  font-weight: bold;
`;

const SettingLine = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
  justify-content: start;
  margin-bottom: 0.5rem;
`;

const Select: FC<SelectHTMLAttributes<HTMLSelectElement>> = ({
  children,
  ...rest
}) => (
  <select
    className="h-8 bg-gray-100 dark:bg-slate-800 w-16 p-1 rounded"
    {...rest}
  >
    {children}
  </select>
);

export default Select;

export function SettingsRoute() {
  const [settings, changeSetting] = useSettings();

  const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeSetting('unit', e.target.value as 'km' | 'miles');
  };

  const handleThemeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    changeSetting('theme', e.target.value as 'light' | 'dark');
  };

  return (
    <Container>
      <BackButton />
      <Title>Settings</Title>
      <SettingLine>
        <Select id="unit" onChange={handleUnitChange} value={settings?.unit}>
          <option value="km">KM</option>
          <option value="miles">Miles</option>
        </Select>
        <label htmlFor="unit">Unit of distance</label>
      </SettingLine>
      <SettingLine>
        <Select
          id="theme"
          onChange={handleThemeChange}
          value={
            settings.theme ||
            (window.matchMedia('(prefers-color-scheme: dark)').matches
              ? 'dark'
              : 'light')
          }
        >
          <option value="dark">Dark</option>
          <option value="light">Light</option>
        </Select>
        <label htmlFor="theme">Theme</label>
      </SettingLine>
      <h3 className="text-lg font-bold">Difficulty Modifiers</h3>
      <SettingLine>
        <input
          type="checkbox"
          id="grayScale"
          className="justify-self-center"
          checked={settings?.grayScale}
          onChange={() => changeSetting('grayScale', !settings?.grayScale)}
        />
        <label htmlFor="grayScale">Grayscale flag</label>
      </SettingLine>
    </Container>
  );
}

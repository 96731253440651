import React from 'react';
import { Link } from 'react-router-dom';

import EmojiRender from './EmojiRender';

export const StatsLinkIcon: React.FC = () => {
  return (
    <Link to="/stats">
      <EmojiRender text="📈" className="mr-3 ml-3 text-xl" />
    </Link>
  );
};

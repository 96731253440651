import { RoundNavigation } from '@pla324/teuteuf-round-navigation';

import { useTodaysCountry } from '../providers/TodaysCountryProvider';

export default function RoundsNavigation() {
  const { rounds, completed, currentRound, navToRound } = useTodaysCountry();

  if (!completed.includes('Flag')) return <div className="flex h-5"></div>;

  return (
    <div className="flex w-full justify-center px-[2px] md:px-0">
      <RoundNavigation
        rounds={rounds}
        currentRound={currentRound}
        getRoundEmojiString={(round) => round.emojis}
        getRoundName={(round) => round.name}
        getRoundCompleted={(round) => completed.includes(round.name)}
        getRoundIsNew={() => false}
        goToRound={(round) => navToRound(round)}
      />
    </div>
  );
}
